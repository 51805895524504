var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.show,"width":"965px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.handelClose}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],ref:"ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"120px"}},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label":"所属项目部","prop":"projectDepartment","required":!_vm.isRead}},[_c('el-select',{staticStyle:{"width":"330px"},attrs:{"placeholder":"请选择","disabled":_vm.isRead},model:{value:(_vm.ruleForm.projectDepartment),callback:function ($$v) {_vm.$set(_vm.ruleForm, "projectDepartment", $$v)},expression:"ruleForm.projectDepartment"}},_vm._l((_vm.projectList),function(item){return _c('el-option',{key:item.orgId,attrs:{"label":item.orgName,"value":item.orgId}})}),1)],1)],1),_c('el-col',{attrs:{"span":10,"offset":2}},[_c('el-form-item',{attrs:{"label":"活动/产品/服务","prop":"activity","required":!_vm.isRead}},[_c('el-input',{staticStyle:{"width":"330px"},attrs:{"placeholder":"请输入活动/产品/服务","clearable":"","disabled":_vm.isRead},model:{value:(_vm.ruleForm.activity),callback:function ($$v) {_vm.$set(_vm.ruleForm, "activity", $$v)},expression:"ruleForm.activity"}})],1)],1)],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableContent,"cell-style":{ background: '#fff' },"size":"medium","border":"","max-height":"400px"}},[_c('el-table-column',{attrs:{"label":"环境风险","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入","maxlength":50,"disabled":_vm.isRead},model:{value:(row.environmentalRisk),callback:function ($$v) {_vm.$set(row, "environmentalRisk", $$v)},expression:"row.environmentalRisk"}})]}}])}),_c('el-table-column',{attrs:{"label":"是否重要","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-radio-group',{attrs:{"placeholder":"点击选择","disabled":_vm.isRead},model:{value:(row.isImportant),callback:function ($$v) {_vm.$set(row, "isImportant", $$v)},expression:"row.isImportant"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("否")])],1)]}}])}),(!_vm.isRead)?_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('span',{staticClass:"danger-btn",on:{"click":function($event){return _vm.deleteSingleConfig(row.id, $index)}}},[_vm._v("删除")])]}}],null,false,2519847970)}):_vm._e()],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[(!_vm.isRead)?_c('div',{staticClass:"add-btn",on:{"click":_vm.handleRiskConfig}},[_vm._v("添加配置项")]):_vm._e(),(!_vm.isRead)?_c('div',[_c('el-button',{on:{"click":_vm.resetForm}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.submitForm}},[_vm._v("确定")])],1):_c('div',[_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.handleDelete}},[_vm._v("删除")]),_c('el-button',{on:{"click":_vm.handleUpdate}},[_vm._v("修改")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }