import request from '@/utils/request.js';
/**
 * 分页查询环境风险管理（不含详情）
 * @returns {Promise}
 */
export function getEnvironmentalRiskPageApi(data) {
  return request({
    url: '/traffic-construction/environmentalRisk/page',
    method: 'post',
    data
  });
}

/**
 * 添加/修改环境风险
 * @returns {Promise}
 */
export function addEnvironmentalRiskApi(data) {
  return request({
    url: '/traffic-construction/environmentalRisk/addOrEdit',
    method: 'post',
    data
  });
}

/**
 * 添加/修改环境风险
 * @returns {Promise}
 */
export function environmentalRiskDetailApi(params) {
  return request({
    url: '/traffic-construction/environmentalRisk/detail',
    method: 'get',
    params
  });
}

/**
 * 删除环境风险管理主表
 * @returns {Promise}
 */
export function deleteEnvironmentalRiskApi(data) {
  return request({
    url: '/traffic-construction/environmentalRisk/delete',
    method: 'post',
    data
  });
}

/**
 * 导出
 * @returns {Promise}
 */
export function ExportEnvironmentalRiskApi(data) {
  return request({
    url: '/traffic-construction/environmentalRisk/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
