<template>
  <div class="operate-button">
    <slot> </slot>
  </div>
</template>
<script>
export default {
  name: 'OperateButton'
};
</script>
<style lang="less" lang="less">
.operate-button {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
