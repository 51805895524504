<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="模糊查询">
          <el-input v-model="queryForm.activity" placeholder="请输入活动/产品/服务" style="width:280px"></el-input>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.projectDepartmentIds" placeholder="请选择所属项目部" multiple collapse-tags  style="width:280px">
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <operate-button>
      <template #default>
        <el-button v-if="$check('env-risk-manage-add')" type="primary" icon="el-icon-plus" @click="hendleAddRisk">新增风险</el-button>
        <el-button v-if="$check('env-risk-manage-export')" :disabled="btnDisabeld" @click="hendleExport"  v-loading.fullscreen="exportLoading">导出</el-button>
      </template>
    </operate-button>
      <!-- 列表 -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tabelLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed align="center"> </el-table-column>
        <el-table-column label="序号" width="55" fixed align="center" type="index">
        </el-table-column>
        </el-table-column>
        <el-table-column prop="projectDepartmentName" label="所属项目部" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        </el-table-column>
        <el-table-column prop="activity" label="活动/产品/服务" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="address" label="操作" fixed="right" width="120" align="center">
          <template slot-scope="{ row }">
            <span v-if="$check('env-risk-manage-edit')" class="action-btn" @click="handleDetail(row.id)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- dialog -->
     <add-dialog
      ref="addFormRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :project-list="projectList"
       @handle-success="handleSuccess">
     </add-dialog>
  </div>
</template>

<script>
import { getDataRangByNature } from 'api/common';
import { exportFun } from '@/utils/export.js';
import { getEnvironmentalRiskPageApi,ExportEnvironmentalRiskApi } from 'api/general-user/env/double-prevention/risk-manage';
import operateButton from 'views/pages/general-user/security-pages/prevention-control/_components/operateButton';
import AddDialog from './addDialog'
export default {
  components: {
    operateButton,
    AddDialog
  },
  data() {
    return {
      tabelLoading:false,
      dialogVisible: false,
      dialogTitle: '',
      isRead: false,
      exportLoading:false,
      btnDisabeld:true,
      queryForm: {
        activity: '',
        projectDepartmentIds: [],
        pageNo: 1,
        pageSize: 10
      },
      tableData:[],
      total:0,
      projectList: [],
       //导出需要的ids
      ids: [],
    };
  },
  methods: {
     handleSuccess(Boolean) {
      //true为返回列表第一页，否则为编辑，在当前页
      if (Boolean) {
        this.queryForm.pageNo = 1;
      }
      this.getEnvironmentalRiskPage();
      //刷新列表
    },
    handleDetail(id){
      this.dialogTitle = '查看详情';
      this.isRead=true;
      this.dialogVisible = true;
      this.$refs.addFormRef.initTabelConfigFunc(id);
    },
    hendleAddRisk(){
      this.dialogTitle = '新增';
      this.dialogVisible = true;
    },
    hendleExport(){
      ExportEnvironmentalRiskApi({
        ids: this.ids
      })
      .then(res => {
        if (res.status === 200) {
          this.exportLoading = false;
          exportFun(res);
          this.$message({
            type: 'success',
            message: '导出数据成功',
            onClose: () => {
              this.$refs.multipleTable.clearSelection();
            }
          });
        }
      })
      .catch(() => {
        this.exportLoading = false;
        this.$refs.multipleTable.clearSelection();
      });
    },
    //获取项目部
    getDataRangByNature() {
      getDataRangByNature({ orgId: '' }).then(res => {
        if (res.code === 200) {
          this.projectList = res.data;
        }
      });
    },
    getEnvironmentalRiskPage(){
      this.tabelLoading=true;
      getEnvironmentalRiskPageApi(this.queryForm).then(res=>{
          this.tabelLoading=false;
          const {totalRows,rows}=res.data;
          this.total=totalRows;
          this.tableData=rows;
      })
    },
    handleSelectionChange(row){
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.ids.length);
    },
    handleSizeChange(size){
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getEnvironmentalRiskPage();
    },
    handelSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getEnvironmentalRiskPage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getEnvironmentalRiskPage();
    },
    handelReset() {
      //重置
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.projectDepartmentIds = [];
      this.queryForm.activity = '';
      this.$refs.multipleTable.clearSort();
      this.getEnvironmentalRiskPage();
    }
  },
  created() {
    this.getDataRangByNature();
    this.getEnvironmentalRiskPage();
  }
};
</script>
