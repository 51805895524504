<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      width="965px"
      :close-on-click-modal="false"
      @close="handelClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" v-loading="dialogLoading">
        <el-row>
          <el-col :span="10">
            <el-form-item label="所属项目部" prop="projectDepartment" :required="!isRead">
              <el-select
                v-model="ruleForm.projectDepartment"
                placeholder="请选择"
                style="width:330px"
                :disabled="isRead"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.orgId"
                  :label="item.orgName"
                  :value="item.orgId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="活动/产品/服务" prop="activity" :required="!isRead">
              <el-input
                v-model="ruleForm.activity"
                placeholder="请输入活动/产品/服务"
                style="width:330px"
                clearable
                :disabled="isRead"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 表格 -->
      <el-table
        :data="tableContent"
        style="width: 100%"
        :cell-style="{ background: '#fff' }"
        size="medium"
        border
        max-height="400px"
      >
        <el-table-column label="环境风险" align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-input
              v-model="row.environmentalRisk"
              placeholder="点击输入"
              :maxlength="50"
              :disabled="isRead"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="是否重要" align="center" width="240">
          <template slot-scope="{ row }">
            <el-radio-group v-model="row.isImportant" placeholder="点击选择" :disabled="isRead">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120" v-if="!isRead">
          <template slot-scope="{ row, $index }">
            <span class="danger-btn" @click="deleteSingleConfig(row.id, $index)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <div class="add-btn" @click="handleRiskConfig" v-if="!isRead">添加配置项</div>
        <div v-if="!isRead">
          <el-button @click="resetForm">取消</el-button>
          <el-button @click="submitForm" type="primary" :loading="btnLoading">确定</el-button>
        </div>
        <div v-else>
          <el-button type="danger" @click="handleDelete">删除</el-button>
          <el-button @click="handleUpdate">修改</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validateRules } from './validateRules.js';
import {
  addEnvironmentalRiskApi,
  environmentalRiskDetailApi,
  deleteEnvironmentalRiskApi
} from 'api/general-user/env/double-prevention/risk-manage';
export default {
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    isRead: {
      //是否可读(查看详情为true)
      type: Boolean,
      required: true,
      default: false
    },
    //这些都是父组件传下来的下拉数据（这里没调取接口，感觉太臃肿了，采取的是传值）
    projectList: {
      //所属项目部
      type: Array,
      required: true,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      show: this.dialogVisible,
      dialogLoading: false,
      btnLoading: false,
      ruleForm: {
        activity: '',
        projectDepartment: '',
        id: ''
      },
      tableContent: []
    };
  },
  methods: {
    handleRiskConfig() {
      let DetailList = {
        environmentalRisk: '',
        isImportant: ''
      };
      this.tableContent.push(DetailList);
    },
    initTabelConfigFunc(id) {
      this.ruleForm.id = id;
      this.environmentalRiskDetail(id);
    },
    handleUpdate() {
      this.dialogLoading = true;
      this.isDisabled = false;
      this.$emit('update:dialogTitle', '修改');
      this.$emit('update:isRead', false);
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    //删除
    handleDelete() {
      this.$confirm('请确认是否删除该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteEnvironmentalRisk();
      });
    },
    deleteEnvironmentalRisk() {
      deleteEnvironmentalRiskApi({ id: this.ruleForm.id }).then(res => {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handle-success', false);
      });
    },
    //风险详情
    environmentalRiskDetail(id) {
      this.dialogLoading = true;
      //配置项的具体配置列表
      environmentalRiskDetailApi({ id: id }).then(res => {
          this.dialogLoading = false;
          let { activity, projectDepartment, detailList } = res.data;
          this.ruleForm.activity = activity;
          this.ruleForm.projectDepartment = projectDepartment;
          this.tableContent = detailList;
      });
    },
    deleteSingleConfig(id, index) {
      //删除
      this.$confirm('确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          // this.deleteSingleConfigurationDetail(id);
        } else {
          this.tableContent.splice(index, 1);
        }
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addEnvironmentalRisk();
        }
      });
    },
    //添加/修改环境风险
    addEnvironmentalRisk() {
      this.btnLoading = true;
      let pamers = this.ruleForm;
      pamers.DetailList = this.tableContent;
      addEnvironmentalRiskApi(pamers)
        .then(res => {
            this.btnLoading = false;
            this.$emit('update:dialogVisible', false);
            this.$emit('handle-success', true);
            this.$message.success('操作成功');
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.$emit('update:isRead', false);
      this.ruleForm = {};
      this.tableContent = [];
      this.$refs.ruleForm.resetFields();
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    }
  }
};
</script>

<style scoped>
.add-btn {
  width: 94px;
  height: 32px;
  border: 1px solid #005aab;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #005aab;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  bottom: 20px;
}
</style>
