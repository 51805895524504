export const validateRules = {
  data() {
    var validateActivity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入活动/产品/服务'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateProjectDepartment = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    return {
      rules: {
        activity: [{ validator: validateActivity }],
        projectDepartment: [{ validator: validateProjectDepartment, trigger: 'change' }]
      }
    };
  }
};
